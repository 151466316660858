import React from "react"
import styled from "styled-components"
import moment from "moment"
import { StaticImage } from "gatsby-plugin-image"

import { Fixture } from "../components/molecules"
import CupIcon from "../assets/icons/trophy.svg"

const Container = styled.article`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    background-color: #ffffff;
    box-shadow: 0px 5px 10px 0px #d8d8d8;
    padding: 1.25em;
    margin-bottom: 1em;
    &:last-of-type {
        margin-bottom: 0;
    }
    @media (min-width: 960px) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    @media (min-width: 640px) and (max-width: 959px) {
        text-align: center;
    }
`

const MatchDetails = styled.div`
    p{
        margin: 0;
    }
    @media (min-width: 960px) {
        text-align: right;
        flex: 0 0 200px;
        order: 3;
    }
    @media (max-width: 959px) {
        order: 1;
    }
`

const MatchDetail = styled.div`
    font-size: 14px;
    margin: 0;
`

const Date = styled(MatchDetail)`
    font-weight: 600;
`

const Competition = styled(MatchDetail)`
    order: 3;
    @media (min-width: 960px) {
        flex: 0 0 200px;
        order: 1;
    }
`

const ExtendedFixture = styled(Fixture)`
    order: 2;
    flex-grow: 1;
    @media (max-width: 959px) {
        margin: 1rem 0 0.75rem;
    }
    @media (max-width: 639px) {
        margin: 1.25rem 0;
    }
`

const StyledCupIcon = styled(CupIcon)`
    width: 40px;
    height: 40px;
`

const FixtureCard = ({ match, type }) => {
    const { title, address, matchDetails, score } = match
    const { addressLineOne, town } = address
    const { date, venue, competition } = matchDetails

    return (
        <Container>
            <Competition>
                {competition === "league" ? (
                    <StaticImage
                        src="../assets/images/yel_logo.png"
                        width={40}
                        height={40}
                        alt="Youth Elizabethan League logo"
                    />
                ) : (
                    <StyledCupIcon />
                )}
            </Competition>
            <ExtendedFixture type={type} opponent={title} score={score} venue={venue} />
            <MatchDetails>
                <Date>
                    <p>{moment(date).format("dddd Do MMMM")}</p>
                </Date>
                <MatchDetail>
                    <p>{`${addressLineOne}, ${town}`}</p>
                </MatchDetail>
            </MatchDetails>
        </Container>
    )
}

export default FixtureCard