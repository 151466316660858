import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { useMediaQuery } from 'react-responsive';

import Layout from '../components/Layout';
import { Container, Filters, FilterItem, TitleContainer, Title, Select } from '../components/atoms';
import { RecentMatch } from '../components/molecules';
import Fixture from '../components/Fixture';

import { getRelativeFixtures, getRelativeResults, filterMatches } from '../utils/filters';

const FilterToggle = styled.button`
    display: block;
    width: 100%;
    background-color: #F4F4F4;
    color: #2B2B2B;
    padding: 1em 1.563em;
    text-align: left;
    font-weight: 600;
    @media (min-width: 768px){
        display: none;
    }
`

const BackgroundContainer = styled.section`
    @media (min-width: 768px) {
        background-color: #f4f4f4;
    }
`
const FutureMatches = styled(Container)`
    padding-top: 3rem;
    padding-bottom: 3rem;
`

const NoResultsContainer = styled(Container)`
    margin-bottom: 3rem;
`

const NoResultsDesc = styled.p`
    margin: 0;
`

const Matches = ({ data }) => {
    const [matches, setMatches] = useState(data.allWpFixture.nodes);
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [type, setMatchType] = useState("fixtures");
    const [team, setTeam] = useState();
    const [venue, setVenue] = useState();
    const [competition, setCompetition] = useState();
    const isGreaterThanMobile = useMediaQuery({ query: '(min-width: 768px)' });

    useEffect(() => {
        const filters = { team, venue, competition };
        let filteredMatches = filterMatches(data.allWpFixture.nodes, filters);
        filteredMatches = type === "fixtures" ? getRelativeFixtures(filteredMatches, 10) : getRelativeResults(filteredMatches, 10);

        setMatches(filteredMatches);
    }, [data.allWpFixture.nodes, type, team, venue, competition])

    const handleTeamChange = ({ target }) => setTeam(target.value === "none" ? undefined : target.value)
    const handleMatchTypeChange = ({ target }) => setMatchType(target.value === "none" ? undefined : target.value)
    const handleVenueChange = ({ target }) => setVenue(target.value === "none" ? undefined : target.value)
    const handleCompChange = ({ target }) => setCompetition(target.value === "none" ? undefined : target.value)
    const handleMatchFilter = () => setIsFilterOpen(!isFilterOpen)

    return (
        <Layout>
            <main>
                <article>
                    <header>
                        <FilterToggle onClick={handleMatchFilter}>Show match filters</FilterToggle>
                        {(isGreaterThanMobile || isFilterOpen) &&
                            <Filters>
                                <FilterItem oneQuarter>
                                    <Select name="team" onChange={handleTeamChange} value={team} showArrow>
                                        <option value="none">Select a team</option>
                                        <option value="under-12s">Under 12s</option>
                                        <option value="under-11s">Under 11s</option>
                                        <option value="under-10s">Under 10s</option>
                                        <option value="under-9s">Under 9s</option>
                                        <option value="under-8s">Under 8s</option>
                                    </Select>
                                </FilterItem>
                                <FilterItem oneQuarter>
                                    <Select name="type" onChange={handleMatchTypeChange} value={type} showArrow>
                                        <option value="fixtures" defaultValue>Fixtures</option>
                                        <option value="results">Results</option>
                                    </Select>
                                </FilterItem>
                                <FilterItem oneQuarter>
                                    <Select name="venue" onChange={handleVenueChange} value={venue} showArrow>
                                        <option value="none">Select a venue</option>
                                        <option value="home">Home</option>
                                        <option value="away">Away</option>
                                    </Select>
                                </FilterItem>
                                <FilterItem oneQuarter>
                                    <Select name="competition" onChange={handleCompChange} value={competition} showArrow>
                                        <option value="none">Select a competition</option>
                                        <option value="league">League</option>
                                        <option value="cup">Cup</option>
                                    </Select>
                                </FilterItem>
                            </Filters>
                        }
                        <TitleContainer maxWidth="73.125em" gutter>
                            <Title as="h1" noMargin>Matches</Title>
                        </TitleContainer>
                    </header>
                    {matches.length > 0 ? (
                        <BackgroundContainer>
                            <RecentMatch
                                title={matches[0].title}
                                hero={matches[0].hero}
                                addressLineOne={matches[0].address.addressLineOne}
                                town={matches[0].address.town}
                                date={matches[0].matchDetails.date}
                                venue={matches[0].matchDetails.venue}
                                competition={matches[0].matchDetails.competition}
                                ernehaleGoals={matches[0].score.ernehale}
                                oppositionGoals={matches[0].score.opposition}
                                type={type}
                            />
                            {matches.length > 1 && (
                                <FutureMatches maxWidth="73.125em" gutter>
                                    {matches.map((match, index) => index > 0 && <Fixture key={match.id} match={match} type={type} />)}
                                </FutureMatches>
                            )}
                        </BackgroundContainer>
                    ) : (
                        <NoResultsContainer maxWidth="73.125em" gutter>
                            <NoResultsDesc>{`No ${type} match your current criteria, please adjust your search.`}</NoResultsDesc>
                        </NoResultsContainer>
                    )}
                </article>
            </main>
        </Layout>
    )
}

export const query = graphql`
    query MatchesPageQuery {
        allWpFixture(limit: 11, sort: {order: ASC, fields: matchDetails___date}) {
            nodes {
                id
                title
                hero {
                    image {
                        localFile {
                            childImageSharp{
                                gatsbyImageData(aspectRatio: 1.77)
                            }
                        }
                    }
                }
                address {
                    addressLineOne
                    town
                    postcode
                }
                score {
                    ernehale
                    opposition
                }
                matchDetails {
                    date
                    competition
                    venue
                }
                teams {
                    nodes {
                        name
                        slug
                    }
                }
            }
        }
    }
`

export default Matches
